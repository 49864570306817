.button {
  all: unset;
  align-items: center;
  border-radius: 10px;
  box-sizing: border-box;
  display: inline-flex;
  gap: 10px;
  padding: 14px 18px;
  position: relative;
  cursor: pointer;
  color: white;
  font-weight: 600;
}

.button .text-wrapper-2 {
  all: unset;
  box-sizing: border-box;

  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.button.property-1-0-default {
  background-color: #3da3db;
}

.button.property-1-0-variant-2 {
  border: 1px solid;
  border-color: #ffffff;
}

.button.property-1-0-default .text-wrapper-2 {
  color: #000000;
}

.button.property-1-0-variant-2 .text-wrapper-2 {
  color: #ffffff;
}

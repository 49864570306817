.history-card {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.history-card .title {
  position: relative;
  text-align: center;
  width: 12.8125rem;
}

.history-card .paragraph {
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: 16.8125rem;
}

.history-card.property-1-7-default {
  gap: 0.3125rem;
}

.history-card.property-1-7-variant-3 {
  gap: 0.3125rem;
}

.history-card.property-1-7-default .title {
  color: #ffffff;
  font-family: var(--h-4-font-family);
  font-size: var(--h-4-font-size);
  font-style: var(--h-4-font-style);
  font-weight: var(--h-4-font-weight);
  letter-spacing: var(--h-4-letter-spacing);
  line-height: var(--h-4-line-height);
}

.history-card.property-1-7-variant-3 .title {
  color: #03577a;
  font-family: var(--h-4-font-family);
  font-size: var(--h-4-font-size);
  font-style: var(--h-4-font-style);
  font-weight: var(--h-4-font-weight);
  letter-spacing: var(--h-4-letter-spacing);
  line-height: var(--h-4-line-height);
}

.history-card.property-1-7-variant-2 .title {
  color: #ffffff;
  font-family: var(--h4-mobile-font-family);
  font-size: var(--h4-mobile-font-size);
  font-style: var(--h4-mobile-font-style);
  font-weight: var(--h4-mobile-font-weight);
  letter-spacing: var(--h4-mobile-letter-spacing);
  line-height: var(--h4-mobile-line-height);
}

.history-card.property-1-7-default .paragraph {
  color: #ffffffbf;
  font-size: 1rem;
}

.history-card.property-1-7-variant-3 .paragraph {
  color: #000000bf;
  font-size: 1rem;
}

.history-card.property-1-7-variant-2 .paragraph {
  color: #ffffffbf;
  font-size: 0.875rem;
}

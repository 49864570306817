.navbar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.25rem; /* Adjust padding for mobile */
  position: relative;
  width: 100%; /* Adjust width for mobile */
  position: fixed;
  z-index: 999;
  top: 0;
  transition: background-color 0.5s ease; /* Add transition for background color */

  /* margin-top: 1rem; */
}

.navbar .logo-instance {
  background-image: url(../../img/eco-07-01-1.png) !important;
  height: 2.2081rem !important;
  position: relative !important;
}

.navbar .menu {
  align-items: flex-start;
  background-color: #000000;
  border-radius: 0.625rem;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3rem;
  padding: 1rem 3rem;
  position: relative;
}

.navbar .text-wrapper,
.navbar .div,
.navbar .contact-us {
  color: #ffffff;
  
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.0625rem;
  position: relative;
  width: fit-content;
}

/* Add styles for mobile menu */
.menu {
  display: none;
}

.menu.open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%; /* Position the menu below the navbar */
  background-color: #000000;
  border-radius: 0.625rem;
  padding: 1rem 3rem;
}

/* Media query for mobile devices */
@media screen and (max-width: 48rem) {
  .navbar {
    padding: 2rem 0.625rem; /* Adjust padding for smaller screens */
  }

  .menu {
    width: 100%; /* Adjust width to fill the screen */
  }

  .menu.open {
    left: 0; /* Position the menu at the left edge */
    right: 0; /* Position the menu at the right edge */
  }
}

/* Add styles for mobile menu toggle */
.menu-toggle {
  display: none; /* Initially hide the toggle icon */
}
.contact-web{
  display: block;
}
.contact-mobile{
  display: none;
}
@media screen and (max-width: 48rem) {
  .menu-toggle {
    display: block; /* Display toggle icon on mobile */
    cursor: pointer;
  }

  .navbar .menu {
    display: none; /* Hide menu by default on mobile */
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #000000;
    border-radius: 0.625rem;

    gap: 1.875rem;
    padding: 3rem 3rem;
  }

  .navbar .menu.open {
    display: flex; /* Show menu when it's open */
    flex-direction: column;
    z-index: 99;
    margin-top: 1rem;

  }
  .contact-web{
    display: none;
  }
  .contact-mobile{
    display: block;
  }
}

/* Adjust the styles for the hamburger icon */
.hamburger-icon {
  width: 1.875rem;
  height: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.hamburger-icon span {
  width: 100%;
  height: 0.1875rem;
  background-color: #fff;
}

/* Styling for the hamburger icon's lines */
.hamburger-icon span:nth-child(1) {
  transform-origin: top;
}

.hamburger-icon span:nth-child(2) {
  transform-origin: bottom;
}

/* Add animation for the hamburger icon when menu is opened */
.hamburger-icon span:nth-child(1),
.hamburger-icon span:nth-child(2) {
  transition: transform 0.3s ease;
}


/* Adjust the styles for the hamburger icon */
.hamburger-icon {
  width: 1.875rem;
  height: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.hamburger-icon span {
  width: 100%;
  height: 0.1875rem;
  background-color: #fff;
  transition: transform 0.3s ease;
}

/* Styling for the hamburger icon's lines */
.hamburger-icon span:nth-child(1) {
  transform-origin: top;
}

.hamburger-icon span:nth-child(3) {
  transform-origin: bottom;
}

/* Rotate the top and bottom lines when menu is opened */
.hamburger-icon.open span:nth-child(1) {
  transform: translateY(0.75rem) rotate(45deg);
}

.hamburger-icon.open span:nth-child(2) {
  opacity: 0;
}

.hamburger-icon.open span:nth-child(3) {
  transform: translateY(-0.3125rem) rotate(-45deg);
}

.menu-button {
  color: #ffffff;
  
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  padding: 0.5rem 0; /* Adjust padding as needed */
  width: fit-content;
}

.navbar.scrolled {
  background-color: #000000;
  transition: background-color 0.5s ease; /* Add transition for background color */
  /* Add black background color when scrolled */
}

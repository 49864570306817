.logos {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 0.625rem;
  justify-content: center;
  position: relative;
  margin-top: 5rem;
  /* margin-bottom: 2rem; */
}

.logos .p {
  color: #ffffff8a;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.logos .frame {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 3.75rem 3.75rem;
  justify-content: center;
  position: relative;
}


.logos img {
  width: 5rem;
  height: 4rem;
  object-fit: contain;
  position: relative;
}


.logos .frame img{
  width: 5rem;
  height: 4rem;
  object-fit: contain;
  position: relative;

}
.contact {
  align-items: center;
  background-color: #ffffff;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.contact .header {
  align-items: center;
  align-self: stretch;
  background-color: transparent;
  background-image: url(../../img/header.webp);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  justify-content: space-between;
  padding: 1.875rem 1rem;
  position: relative;
  width: 100%;
}

.contact .design-component-instance-node {
  flex: 0 0 auto !important;
}

.contact .navbar-instance {
  color: #ffffff8a !important;
}

.contact .hero {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 1.5rem;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-top: 3rem;
  padding: 0 1rem;
}

.contact .text {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 0.625rem;
  position: relative;
  width: 100%;
}

.contact .subtitle-instance {
  background-blend-mode: overlay !important;
  background-color: #ffffff !important;
  flex: 0 0 auto !important;
}

.contact .hero .subtitle {
  background-blend-mode: overlay !important;
  background-color: #383e8e !important;
  color: white;
  flex: 0 0 auto !important;
}

.contact .h {
  align-self: stretch;
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 4.8125rem;
  position: relative;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .contact .h {
    font-size: 3rem;
  
  }
}

.contact .our-forte-lies-in {
  align-self: stretch;
  color: #ffffffbf;
  font-family: "Poppins", Helvetica;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.contact .shaping {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3rem;
  justify-content: center;
  padding: 6.25rem 0rem;
  position: relative;
  width: 100%;
}

.contact .head {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  position: relative;
  width: 100%;
}

.contact .subtitle-3 {
  flex: 0 0 auto !important;
  justify-content: center !important;
}

.contact .let-us-know-more {
  align-self: stretch;
  color: #03577a;
  font-family: "Poppins", Helvetica;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 3.75rem;
  position: relative;
  text-align: center;
}

.contact .we-don-t-just-stop {
  align-self: stretch;
  color: #0000008a;
  font-family: "Poppins", Helvetica;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.contact .form {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 1.875rem;
  justify-content: center;
  position: relative;
}

.contact .input-instance {
  flex: 0 0 auto !important;
  width: 29.5rem !important;
}

.service-card {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 0.625rem;
  position: relative;
}

.service-card .icon {
  align-items: center;
  border: 0.0625rem solid;
  border-color: #3da3db;
  flex-direction: column;
  gap: 0.625rem;
  justify-content: center;
  position: relative;
}

.service-card .text-wrapper-3 {
  color: #000000;
  
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.service-card .text {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  text-align: start;
}

.service-card .AI-driven-analytics {
  color: #03577a;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.0625rem;
  position: relative;
  width: 100%;

}

.service-card .text-wrapper-4 {
  
  font-size: 0.875rem;
  font-weight: 400;
  height: 6.5625rem;
  letter-spacing: 0;
  position: relative;
  width: 19.5625rem;
}

.service-card .property-1-2-default {
  border-radius: 0.625rem;
  display: flex;
  height: 2.25rem;
  padding: 0.625rem 0.8125rem;
  width: 2.25rem;
}

.service-card .property-1-2-variant-2 {
  border-radius: 0.3125rem;
  display: inline-flex;
  flex: 0 0 auto;
  padding: 0.3125rem 0.5625rem;
}

.service-card .property-1-4-default {
  gap: 0.3125rem;
}

.service-card .icon.property-1-2-default .text-wrapper-3 {
  margin-bottom: -0.0938rem;
  margin-left: -0.125rem;
  margin-right: -0.125rem;
  margin-top: -0.2188rem;
}

.service-card .icon.property-1-2-variant-2 .text-wrapper-3 {
  margin-top: -0.0625rem;
}

.service-card .text.property-1-4-default .AI-driven-analytics {
  font-size: 1.125rem;
}

.service-card .text.property-1-4-variant-2 .AI-driven-analytics {
  font-size: 1rem;
}

.service-card .text.property-1-4-default .text-wrapper-4 {
  color: #000000;
  line-height: normal;
}

.service-card .text.property-1-4-variant-2 .text-wrapper-4 {
  color: #0000008a;
  line-height: 1.25rem;
}

.service-section {
  background-image: url(../../img/service.webp);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 1.25rem;
  box-shadow: 0.25rem 0.625rem 1.4375rem #00000029;
  padding: 3rem;

  display: grid;
  position: relative;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1.875rem 1.875rem;
  align-items: center;
  color: white;
width: 95%;
text-align: start;
min-height: 24rem;
line-height: 1.5;
display: flex;
align-items: center;
@media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.service-section.reverse .preview {
  order: 0;
}
.service-section .preview {
max-width: 40%;
}
@media (min-width: 768px) {
  .service-section .preview {
    order: 2;
  }

 
}
@media (max-width: 768px) {


  .service-section{
    flex-wrap: wrap;
    max-width: 95vw;
    padding: 5vw;
  }

  .service-section .preview{
width: 100%;
max-width: 100%;
  }
}
.service-section .details .subtitle {
  color: #3da3db;
}
.service-section .details .title {
  font-size: xx-large;
  font-weight: 600;
}
.service-section .details {
  display: flex;
  flex-direction: column;
  align-items: start;
  /* gap: 1rem; */
}
.service-section .preview {
  /* width: 100%;
  height: 100%; */
  height: 18rem;
  object-fit: cover;
  border-radius: 1rem;
}

.service-section .subtitle-instance {
  font-size: 0.75rem !important;
  font-weight: 700 !important;
}

.service-section .class {
  flex: 0 0 auto !important;
}

.service-section .class-2 {
  flex: 0 0 auto !important;
  opacity: 0.8 !important;
}

.service-section .text-wrapper-2 {
  font-size: 2.5rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 3.125rem;
  position: relative;
}

.service-section .embrace-the-future {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.service-section.property-1-0-variant-4 {
  /* background-image: url(../../../static/img/property-1-variant4.svg); */
}

.service-section.property-1-0-default {
  /* background-image: url(../../../static/img/property-1-default.svg); */
}

.service-section.property-1-0-variant-3 {
  /* background-image: url(../../../static/img/property-1-variant3.svg); */
}

.service-section.property-1-0-variant-2 {
  /* background-image: url(../../../static/img/property-1-variant2.svg); */
}

.service-section.property-1-0-variant-4 .text-wrapper-2 {
  color: #ffffff;
}

.service-section.property-1-0-default .text-wrapper-2 {
  color: #ffffffcc;
}

.service-section.property-1-0-variant-3 .text-wrapper-2 {
  color: #ffffffcc;
}

.service-section.property-1-0-variant-2 .text-wrapper-2 {
  color: #ffffff;
}

.service-section.property-1-0-variant-4 .embrace-the-future {
  color: #ffffff;
}

.service-section.property-1-0-default .embrace-the-future {
  color: #ffffff8a;
}

.service-section.property-1-0-variant-3 .embrace-the-future {
  color: #ffffff8a;
}

.service-section.property-1-0-variant-2 .embrace-the-future {
  color: #ffffff;
}

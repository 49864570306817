.philosophy-card {
  align-items: flex-start;
  background-color: #00000033;
  border-radius: 0.9375rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  overflow: hidden;
  padding: 2.5rem 1.875rem;
  position: relative;
  flex: 1;
}
@media screen and (max-width: 768px) {
  .philosophy-card {
    flex: auto;
  }
}
.philosophy-card .user-robot {
  height: 2.25rem !important;
  position: relative !important;
  width: 2.25rem !important;
}

.philosophy-card .frame {
  align-items: flex-start;
  
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 0.875rem;
  justify-content: center;
  padding: 0rem 0rem 1rem;
  position: relative;
  width: 100%;
  text-align: start;
}

.philosophy-card .OUR-VISION {
  
  color: #ffffff;
  
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.0625rem;
  position: relative;
}

.philosophy-card .ECO-has-designed {
  
  color: #ffffff;
  
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}


.philosophy-card .fi{
  color: #3da3db;
  font-size: 2rem;
  align-items: center;
  display: flex;
  justify-content: center;
}
.contact-card {
  align-items: center;
  display: inline-flex;
  gap: 16px;
  position: relative;
}

.contact-card .phone-number {
  color: #ffffff;
}
.contact-cards {
  display: flex;
  gap: 3rem;
  flex-wrap: wrap;
}

.contact-card .div {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
}

.contact-card .content {
  flex: 0 0 auto !important;
  position: relative !important;
  color: white;
  font-weight: 500;
}

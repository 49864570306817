.about {
  align-items: center;
  background-color: #ffffff;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  text-align: center;
  justify-content: center;
  display: flex;
}

.about .header {
  align-items: center;
  background-color: transparent;
  background-image: url(../../img/header.webp);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 50vh;
  justify-content: space-between;
  padding: 1.875rem 1rem;
  position: relative;
  width: 100%;
}

.about .design-component-instance-node {
  flex: 0 0 auto !important;
}

.about .navbar-instance {
  color: #ffffff8a !important;
}

.about .hero {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 1.5rem;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-top: 3rem;
  padding: 0 1rem;
}

.about .text {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 0.625rem;
  position: relative;
  width: 100%;
}

.about .subtitle-instance {
  background-blend-mode: overlay !important;
  background-color: #ffffff !important;
  flex: 0 0 auto !important;
}

.about .h {
  color: #ffffff;
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: 0;
  position: relative;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .about .h {
    font-size: 3rem;
  }
}

.about .our-forte-lies-in {
  color: #ffffffbf;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  max-width: 50rem;
}

.about .our-history {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.25rem;
  padding: 3.75rem 0.9375rem 6.25rem;
  position: relative;
  width: 100%;
}

.about .head {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.about .subtitle-2 {
  flex: 0 0 auto !important;
  justify-content: center !important;
}

.about .text-wrapper-2 {
  color: #03577a;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.about .we-don-t-just-stop {
  color: #0000008a;

  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  max-width: 45rem;
}

.about .success {
  align-items: center;
  background-image: url(../../img/bg.webp);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 6.25rem 0.9375rem;
  position: relative;
  width: 100%;
}

.about .subtitle-3 {
  background-color: #03577a !important;
  flex: 0 0 auto !important;
  padding: 0.125rem 0.875rem !important;
}

.about .subtitle-4 {
  color: #ffffff !important;
  font-size: 0.75rem !important;
}

.about .text-wrapper-3 {
  color: #3da3db;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.about .we-don-t-just-stop-2 {
  color: #ffffff8a;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.about .cards-2 {
  position: relative;
  display: flex;
  gap: 2rem;

  @media (min-width: 768px) {
    /* grid-template-columns: repeat(2, minmax(0, 1fr)); */
  }
}
@media screen and (max-width: 768px) {
  .about .cards-2 {
    justify-content: flex-start;
  flex-direction: column;  
  }
} 

.about .shaping {
  padding: 100px 0px;
  display: grid;
  position: relative;
  align-items: center;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.about .head-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0rem 0rem 0rem 6.25rem;
  position: relative;
  text-align: start;
}

@media screen and (max-width: 768px) {
  .about .head-2 {
    padding: 0rem 0rem 0rem 1rem;
  }
}

.about .text-wrapper-4 {
  color: #03577a;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.about .we-don-t-just-stop-3 {
  color: #0000008a;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  max-width: 45rem;
}

.about .cards-3 {
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 1.5rem 1.5rem;
  justify-content: center;
  padding: 0rem 4.5rem;
  position: relative;
}

.about .shaping-card-instance {
  flex: 0 0 auto !important;
  overflow: unset !important;
}

.about .shaping-2 {
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4.5rem;
  justify-content: center;
  padding: 6.25rem 0rem;
  position: relative;
  width: 100%;
}

.about .head-3 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}

.about .alliance {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  position: relative !important;
  width: 100% !important;
}

.about .space {
  background-color: #ffffff;
  height: 4.5rem;
  position: relative;
  width: 100%;
}

.about .our-history-2 {
  align-items: center;
  background-image: url(../../img/bg.webp);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 2.8125rem;
  box-shadow: 0.25rem 0.625rem 1.4375rem #00000029;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3.75rem;
  justify-content: center;
  padding: 3.75rem 0.9375rem 4.5rem;
  position: relative;
  width: 90%;
  margin: 0 5%;
}

.about .text-wrapper-5 {
  color: #3da3db;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.0625rem;
  position: relative;
  text-align: center;
}

.about .we-don-t-just-stop-4 {
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  max-width: 45rem;
}

.about .icon-instance-node {
  flex: 0 0 auto !important;
  position: relative !important;
}

.about .logos-instance {
  align-self: stretch !important;
  background-color: #03577a !important;
  display: flex !important;
  flex: 0 0 auto !important;
  padding: 2.25rem 0rem !important;
  width: 100% !important;
  margin-top: 0 !important;
}

.about .logos-2 {
  align-self: stretch !important;
  display: flex !important;
  width: 100% !important;
}

.logos-animation{
  width: 100%;

}
.logos-animation .anchor{
  width: 100%;
}

.alliance-icons{
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 3rem;
  align-items: center;
}

.alliance-icons img{
  width: 7rem;
  height: 5rem;
}
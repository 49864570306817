.button {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  color: #fff;
  border-radius: 10px;
  align-items: center;
  gap: 10px;
  padding: 14px 18px;
  font-weight: 600;
  display: inline-flex;
  position: relative;
}

.button .text-wrapper-2 {
  all: unset;
  box-sizing: border-box;
  letter-spacing: 0;
  width: fit-content;
  margin-top: -1px;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.button.property-1-0-default {
  background-color: #3da3db;
}

.button.property-1-0-variant-2 {
  border: 1px solid #fff;
}

.button.property-1-0-default .text-wrapper-2 {
  color: #000;
}

.button.property-1-0-variant-2 .text-wrapper-2 {
  color: #fff;
}

.history-card {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.history-card .title {
  text-align: center;
  width: 12.8125rem;
  position: relative;
}

.history-card .paragraph {
  letter-spacing: 0;
  text-align: center;
  width: 16.8125rem;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.history-card.property-1-7-default, .history-card.property-1-7-variant-3 {
  gap: .3125rem;
}

.history-card.property-1-7-default .title {
  color: #fff;
  font-family: var(--h-4-font-family);
  font-size: var(--h-4-font-size);
  font-style: var(--h-4-font-style);
  font-weight: var(--h-4-font-weight);
  letter-spacing: var(--h-4-letter-spacing);
  line-height: var(--h-4-line-height);
}

.history-card.property-1-7-variant-3 .title {
  color: #03577a;
  font-family: var(--h-4-font-family);
  font-size: var(--h-4-font-size);
  font-style: var(--h-4-font-style);
  font-weight: var(--h-4-font-weight);
  letter-spacing: var(--h-4-letter-spacing);
  line-height: var(--h-4-line-height);
}

.history-card.property-1-7-variant-2 .title {
  color: #fff;
  font-family: var(--h4-mobile-font-family);
  font-size: var(--h4-mobile-font-size);
  font-style: var(--h4-mobile-font-style);
  font-weight: var(--h4-mobile-font-weight);
  letter-spacing: var(--h4-mobile-letter-spacing);
  line-height: var(--h4-mobile-line-height);
}

.history-card.property-1-7-default .paragraph {
  color: #ffffffbf;
  font-size: 1rem;
}

.history-card.property-1-7-variant-3 .paragraph {
  color: #000000bf;
  font-size: 1rem;
}

.history-card.property-1-7-variant-2 .paragraph {
  color: #ffffffbf;
  font-size: .875rem;
}

.logos {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .625rem;
  margin-top: 5rem;
  display: inline-flex;
  position: relative;
}

.logos .p {
  color: #ffffff8a;
  width: fit-content;
  font-size: .875rem;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.logos .frame {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 3.75rem;
  display: inline-flex;
  position: relative;
}

.logos img, .logos .frame img {
  object-fit: contain;
  width: 5rem;
  height: 4rem;
  position: relative;
}

.logo {
  background-image: url("eco-07-01-1-1.99597fc9.png");
  background-position: 50%;
  background-size: cover;
  width: 8rem;
  height: 2.1875rem;
}

.navbar {
  z-index: 999;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  transition: background-color .5s;
  display: flex;
  position: fixed;
  top: 0;
}

.navbar .logo-instance {
  background-image: url("eco-07-01-1.5b05b393.png") !important;
  height: 2.2081rem !important;
  position: relative !important;
}

.navbar .menu {
  background-color: #000;
  border-radius: .625rem;
  flex: none;
  align-items: flex-start;
  gap: 3rem;
  padding: 1rem 3rem;
  display: inline-flex;
  position: relative;
}

.navbar .text-wrapper, .navbar .div, .navbar .contact-us {
  color: #fff;
  letter-spacing: 0;
  width: fit-content;
  margin-top: -.0625rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.menu {
  display: none;
}

.menu.open {
  background-color: #000;
  border-radius: .625rem;
  flex-direction: column;
  padding: 1rem 3rem;
  display: flex;
  position: absolute;
  top: 100%;
}

@media screen and (width <= 48rem) {
  .navbar {
    padding: 2rem .625rem;
  }

  .menu {
    width: 100%;
  }

  .menu.open {
    left: 0;
    right: 0;
  }
}

.menu-toggle {
  display: none;
}

.contact-web {
  display: block;
}

.contact-mobile {
  display: none;
}

@media screen and (width <= 48rem) {
  .menu-toggle {
    cursor: pointer;
    display: block;
  }

  .navbar .menu {
    background-color: #000;
    border-radius: .625rem;
    gap: 1.875rem;
    padding: 3rem;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  }

  .navbar .menu.open {
    z-index: 99;
    flex-direction: column;
    margin-top: 1rem;
    display: flex;
  }

  .contact-web {
    display: none;
  }

  .contact-mobile {
    display: block;
  }
}

.hamburger-icon span {
  background-color: #fff;
  width: 100%;
  height: .1875rem;
}

.hamburger-icon span:nth-child(2) {
  transform-origin: bottom;
}

.hamburger-icon span:first-child, .hamburger-icon span:nth-child(2) {
  transition: transform .3s;
}

.hamburger-icon {
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  width: 1.875rem;
  height: 1.25rem;
  display: flex;
}

.hamburger-icon span {
  background-color: #fff;
  width: 100%;
  height: .1875rem;
  transition: transform .3s;
}

.hamburger-icon span:first-child {
  transform-origin: top;
}

.hamburger-icon span:nth-child(3) {
  transform-origin: bottom;
}

.hamburger-icon.open span:first-child {
  transform: translateY(.75rem)rotate(45deg);
}

.hamburger-icon.open span:nth-child(2) {
  opacity: 0;
}

.hamburger-icon.open span:nth-child(3) {
  transform: translateY(-.3125rem)rotate(-45deg);
}

.menu-button {
  color: #fff;
  width: fit-content;
  padding: .5rem 0;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
}

.navbar.scrolled {
  background-color: #000;
  transition: background-color .5s;
}

.service-card {
  flex-direction: column;
  align-items: flex-start;
  gap: .625rem;
  display: inline-flex;
  position: relative;
}

.service-card .icon {
  border: .0625rem solid #3da3db;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .625rem;
  position: relative;
}

.service-card .text-wrapper-3 {
  color: #000;
  letter-spacing: 0;
  width: fit-content;
  font-size: .875rem;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.service-card .text {
  text-align: start;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.service-card .AI-driven-analytics {
  color: #03577a;
  letter-spacing: 0;
  width: 100%;
  margin-top: -.0625rem;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.service-card .text-wrapper-4 {
  letter-spacing: 0;
  width: 19.5625rem;
  height: 6.5625rem;
  font-size: .875rem;
  font-weight: 400;
  position: relative;
}

.service-card .property-1-2-default {
  border-radius: .625rem;
  width: 2.25rem;
  height: 2.25rem;
  padding: .625rem .8125rem;
  display: flex;
}

.service-card .property-1-2-variant-2 {
  border-radius: .3125rem;
  flex: none;
  padding: .3125rem .5625rem;
  display: inline-flex;
}

.service-card .property-1-4-default {
  gap: .3125rem;
}

.service-card .icon.property-1-2-default .text-wrapper-3 {
  margin: -.2188rem -.125rem -.0938rem;
}

.service-card .icon.property-1-2-variant-2 .text-wrapper-3 {
  margin-top: -.0625rem;
}

.service-card .text.property-1-4-default .AI-driven-analytics {
  font-size: 1.125rem;
}

.service-card .text.property-1-4-variant-2 .AI-driven-analytics {
  font-size: 1rem;
}

.service-card .text.property-1-4-default .text-wrapper-4 {
  color: #000;
  line-height: normal;
}

.service-card .text.property-1-4-variant-2 .text-wrapper-4 {
  color: #0000008a;
  line-height: 1.25rem;
}

.shaping-card {
  border-radius: 4.5rem;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.shaping-card .continuous {
  letter-spacing: 0;
  width: fit-content;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.shaping-card .user-robot {
  width: 1.5rem !important;
  height: 1.5rem !important;
  position: relative !important;
}

.shaping-card.property-1-10-default {
  background-color: #3da3db40;
  gap: 1rem;
  padding: .625rem 1.5rem;
}

.shaping-card.property-1-10-variant-3 {
  background-color: #3da3db40;
  gap: .625rem;
  padding: .3125rem .875rem;
}

.shaping-card.property-1-10-variant-4 {
  background-color: #3da3db1a;
  gap: .625rem;
  padding: .3125rem .875rem;
}

.shaping-card.property-1-10-variant-2 {
  background-color: #3da3db1a;
  gap: 1rem;
  padding: .625rem 1.5rem;
}

.shaping-card.property-1-10-default .continuous {
  color: #03577a;
  font-size: 1.125rem;
}

.shaping-card.property-1-10-variant-3 .continuous {
  color: #03577a;
  font-size: 1rem;
}

.shaping-card.property-1-10-variant-4 .continuous {
  color: #3da3db;
  font-size: 1rem;
}

.shaping-card.property-1-10-variant-2 .continuous {
  color: #3da3db;
  font-size: 1.125rem;
}

.shaping-card.property-1-10-variant-2 .fi {
  color: #3da3db;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  display: flex;
}

.shaping-card.property-1-10-default .fi {
  color: #03577a;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  display: flex;
}

.subtitle {
  border-radius: 4.5rem;
  align-items: center;
  gap: .625rem;
  display: inline-flex;
  position: relative;
}

.subtitle .custom-SAP-solutions {
  letter-spacing: 0;
  width: fit-content;
  line-height: normal;
  position: relative;
}

.subtitle.variant-4, .subtitle.variant-2 {
  padding: .25rem .875rem;
}

.subtitle.variant-5 {
  background-color: #3da3db8a;
  padding: .125rem .875rem;
}

.subtitle.default {
  background-color: #3da3db;
  padding: .25rem .875rem;
}

.subtitle.variant-4 .custom-SAP-solutions {
  color: #03577a;
  font-size: .875rem;
  font-weight: 500;
}

.subtitle.variant-2 .custom-SAP-solutions {
  color: #000;
  font-size: .875rem;
  font-weight: 500;
}

.subtitle.variant-3 .custom-SAP-solutions {
  color: #3da3db;
  font-size: .875rem;
  font-weight: 500;
}

.subtitle.variant-5 .custom-SAP-solutions {
  color: #03577a;
  font-size: .75rem;
  font-weight: 600;
}

.subtitle.default .custom-SAP-solutions {
  font-size: .875rem;
  font-weight: 500;
}

.support-card {
  background-color: #0003;
  border-radius: .9375rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 25.875rem;
  transition: transform .3s ease-in-out;
  display: flex;
  position: relative;
  overflow: hidden;
}

.support-card:hover {
  transform: translateY(-5px);
}

.support-card .property-1-variant2 {
  width: 2.25rem !important;
  height: 2.25rem !important;
  position: relative !important;
}

.support-card .frame-2 {
  text-align: start;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.support-card .text-wrapper-5 {
  color: #fff;
  letter-spacing: 0;
  margin-top: -.0625rem;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.support-card .text-wrapper-6 {
  color: #ffffff8a;
  letter-spacing: 0;
  font-size: .875rem;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.support-card.property-1-12-default {
  gap: 1.125rem;
  padding: 2.5rem 1.875rem;
}

.support-card.property-1-12-variant-2 {
  gap: .875rem;
  padding: 1.5rem;
}

.support-card.property-1-12-default .frame-2 {
  gap: .3125rem;
}

.support-card.property-1-12-variant-2 .frame-2 {
  gap: .25rem;
}

.support-card.property-1-12-default .text-wrapper-5 {
  font-size: 1.25rem;
}

.support-card.property-1-12-variant-2 .text-wrapper-5 {
  font-size: 1.125rem;
}

.support-card .fi {
  color: #3da3db;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  display: flex;
}

.footer {
  background-color: #0b1123;
  flex-flow: column wrap;
  justify-content: space-between;
  gap: 5rem;
  width: 100%;
  padding: 4rem 6.25rem 1.25rem;
  display: flex;
}

.eco-logo {
  width: 10.1131rem !important;
  height: 4.5rem !important;
}

.links {
  text-align: start;
  flex-wrap: wrap;
  gap: 3rem;
  display: flex;
}

.frame-3 {
  flex-direction: column;
  gap: .6rem;
  display: flex;
}

.text-wrapper-12 {
  color: #fff;
  font-size: .875rem;
  font-weight: 600;
}

.text-wrapper-13 {
  color: #ffffff8a;
  font-size: .875rem;
  font-weight: 500;
}

footer.flex {
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1.875rem;
  width: 100%;
  display: flex;
}

@media (width <= 768px) {
  .logo-footer {
    justify-content: center;
  }
}

.home {
  text-align: center;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  line-height: 1.5;
  display: flex;
}

.home .header {
  background-image: linear-gradient(#00000073, #00000073), url("header.fa333939.webp");
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8rem;
  width: 100%;
  padding: 5rem 1rem 1rem;
  display: flex;
}

.home .design-component-instance-node {
  flex: none !important;
}

.home .hero {
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  max-width: 48rem;
  margin-top: 8rem;
  display: flex;
}

.home .text-2 {
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  display: flex;
}

.home .subtitle-instance {
  background-color: #fff !important;
}

.home .h {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.2;
  color: #fff !important;
}

@media screen and (width <= 768px) {
  .home .h {
    font-size: 3rem;
  }
}

.home .h span {
  color: #3da3db;
}

.home .our-forte-lies-in {
  color: #ffffffbf;
  font-size: 1rem;
  font-weight: 500;
}

.home .logos-instance {
  width: 100% !important;
  display: flex !important;
}

.home .our-services {
  background-color: #ffffffe0;
  flex-direction: column;
  align-items: center;
  gap: 6.25rem;
  width: 100%;
  padding: 8rem 1rem;
  display: flex;
}

.home .head {
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 48rem;
  display: flex;
}

.home .our-services-2 {
  font-size: 3rem;
  font-weight: 700;
}

.home h2 {
  color: #03577a;
}

.home .we-don-t-just-stop {
  color: #0000008a;
  font-size: .875rem;
  font-weight: 500;
}

.home .cards {
  gap: 1.4375rem;
  width: 100%;
  display: flex;
  overflow: scroll hidden;
}

.home .our-history {
  background-image: url("bg.1e7001e4.webp");
  background-position: 50%;
  background-size: cover;
  border-radius: 2.8125rem;
  flex-direction: column;
  align-items: center;
  gap: 6.25rem;
  width: 90vw;
  margin: 0 5vw;
  padding: 3.75rem .9375rem;
  display: flex;
  box-shadow: .25rem .625rem 1.4375rem #00000029;
}

.home .text-wrapper-9 {
  color: #3da3db;
  font-size: 3rem;
  font-weight: 700;
}

.home .we-don-t-just-stop-2 {
  color: #ffffff8a;
  font-size: .875rem;
  font-weight: 500;
}

.home .cards-2 {
  flex-wrap: wrap;
  justify-content: center;
  gap: 2.75rem;
  width: 100%;
  display: flex;
}

.home .shaping {
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  width: 100%;
  padding: 6.25rem 0;
  display: flex;
}

.home .subtitle-2 {
  flex: none !important;
  justify-content: center !important;
}

.home .text-wrapper-10 {
  color: #03577a;
  font-size: 3rem;
  font-weight: 700;
}

.home .cards-3 {
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
  padding: 0 9.6875rem;
  display: flex;
}

.home .shaping-card-instance {
  overflow: unset !important;
  flex: none !important;
}

.home .success {
  background-image: url("bg.1e7001e4.webp");
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  width: 100%;
  padding: 6.25rem .9375rem;
  display: flex;
}

.home .subtitle-3 {
  background-color: #03577a !important;
}

.home .subtitle-4 {
  color: #fff !important;
  font-size: .75rem !important;
}

.home .text-wrapper-11 {
  color: #3da3db;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
}

.home .cards-4 {
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.25rem;
  width: 100%;
  display: flex;
}

.contact-card {
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.contact-card .phone-number {
  color: #fff;
}

.contact-cards {
  flex-wrap: wrap;
  gap: 3rem;
  display: flex;
}

.contact-card .div {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}

.contact-card .content {
  color: #fff;
  font-weight: 500;
  flex: none !important;
  position: relative !important;
}

.philosophy-card {
  background-color: #0003;
  border-radius: .9375rem;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 1.5rem;
  padding: 2.5rem 1.875rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media screen and (width <= 768px) {
  .philosophy-card {
    flex: auto;
  }
}

.philosophy-card .user-robot {
  width: 2.25rem !important;
  height: 2.25rem !important;
  position: relative !important;
}

.philosophy-card .frame {
  text-align: start;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: .875rem;
  width: 100%;
  padding: 0 0 1rem;
  display: flex;
  position: relative;
}

.philosophy-card .OUR-VISION {
  color: #fff;
  letter-spacing: 0;
  margin-top: -.0625rem;
  font-size: 2rem;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.philosophy-card .ECO-has-designed {
  color: #fff;
  letter-spacing: 0;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.philosophy-card .fi {
  color: #3da3db;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  display: flex;
}

.about {
  text-align: center;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.about .header {
  background-color: #0000;
  background-image: url("header.fa333939.webp");
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50vh;
  padding: 1.875rem 1rem;
  display: flex;
  position: relative;
}

.about .design-component-instance-node {
  flex: none !important;
}

.about .navbar-instance {
  color: #ffffff8a !important;
}

.about .hero {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  margin-top: 3rem;
  padding: 0 1rem;
  display: flex;
  position: relative;
}

.about .text {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: .625rem;
  width: 100%;
  display: flex;
  position: relative;
}

.about .subtitle-instance {
  background-blend-mode: overlay !important;
  background-color: #fff !important;
  flex: none !important;
}

.about .h {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-size: 4rem;
  font-weight: 700;
  position: relative;
}

@media screen and (width <= 768px) {
  .about .h {
    font-size: 3rem;
  }
}

.about .our-forte-lies-in {
  color: #ffffffbf;
  letter-spacing: 0;
  text-align: center;
  max-width: 50rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.about .our-history {
  background-color: #fff;
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 6.25rem;
  width: 100%;
  padding: 3.75rem .9375rem 6.25rem;
  display: flex;
  position: relative;
}

.about .head {
  flex-direction: column;
  flex: none;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.about .subtitle-2 {
  flex: none !important;
  justify-content: center !important;
}

.about .text-wrapper-2 {
  color: #03577a;
  letter-spacing: 0;
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.about .we-don-t-just-stop {
  color: #0000008a;
  letter-spacing: 0;
  text-align: center;
  max-width: 45rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.about .success {
  background-image: url("bg.1e7001e4.webp");
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  width: 100%;
  padding: 6.25rem .9375rem;
  display: flex;
  position: relative;
}

.about .subtitle-3 {
  background-color: #03577a !important;
  flex: none !important;
  padding: .125rem .875rem !important;
}

.about .subtitle-4 {
  color: #fff !important;
  font-size: .75rem !important;
}

.about .text-wrapper-3 {
  color: #3da3db;
  letter-spacing: 0;
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.about .we-don-t-just-stop-2 {
  color: #ffffff8a;
  letter-spacing: 0;
  text-align: center;
  font-size: .875rem;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.about .cards-2 {
  gap: 2rem;
  display: flex;
  position: relative;
}

@media screen and (width <= 768px) {
  .about .cards-2 {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.about .shaping {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  align-items: center;
  gap: 1rem;
  padding: 100px 0;
  display: grid;
  position: relative;

  @media (width >= 768px) {
    & {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

.about .head-2 {
  text-align: start;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 0 0 6.25rem;
  display: flex;
  position: relative;
}

@media screen and (width <= 768px) {
  .about .head-2 {
    padding: 0 0 0 1rem;
  }
}

.about .text-wrapper-4 {
  color: #03577a;
  letter-spacing: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.about .we-don-t-just-stop-3 {
  color: #0000008a;
  letter-spacing: 0;
  max-width: 45rem;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.about .cards-3 {
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  gap: 1.5rem;
  padding: 0 4.5rem;
  display: flex;
  position: relative;
}

.about .shaping-card-instance {
  overflow: unset !important;
  flex: none !important;
}

.about .shaping-2 {
  background-color: #f5f5f5;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4.5rem;
  width: 100%;
  padding: 6.25rem 0;
  display: flex;
  position: relative;
}

.about .head-3 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.about .alliance {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  position: relative !important;
}

.about .space {
  background-color: #fff;
  width: 100%;
  height: 4.5rem;
  position: relative;
}

.about .our-history-2 {
  background-image: url("bg.1e7001e4.webp");
  background-position: 50%;
  background-size: cover;
  border-radius: 2.8125rem;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 3.75rem;
  width: 90%;
  margin: 0 5%;
  padding: 3.75rem .9375rem 4.5rem;
  display: flex;
  position: relative;
  box-shadow: .25rem .625rem 1.4375rem #00000029;
}

.about .text-wrapper-5 {
  color: #3da3db;
  letter-spacing: 0;
  text-align: center;
  margin-top: -.0625rem;
  font-size: 3rem;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.about .we-don-t-just-stop-4 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  max-width: 45rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.about .icon-instance-node {
  flex: none !important;
  position: relative !important;
}

.about .logos-instance {
  background-color: #03577a !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  margin-top: 0 !important;
  padding: 2.25rem 0 !important;
  display: flex !important;
}

.about .logos-2 {
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.logos-animation, .logos-animation .anchor {
  width: 100%;
}

.alliance-icons {
  justify-content: center;
  align-items: center;
  gap: 3rem;
  width: 100%;
  display: flex;
}

.alliance-icons img {
  width: 7rem;
  height: 5rem;
}

.service-section {
  color: #fff;
  text-align: start;
  background-image: url("service.314d279e.webp");
  background-position: 50%;
  background-size: cover;
  border-radius: 1.25rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  align-items: center;
  gap: 1.875rem;
  width: 95%;
  min-height: 24rem;
  padding: 3rem;
  line-height: 1.5;
  display: flex;
  position: relative;
  box-shadow: .25rem .625rem 1.4375rem #00000029;

  @media (width >= 768px) {
    & {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

.service-section.reverse .preview {
  order: 0;
}

.service-section .preview {
  max-width: 40%;
}

@media (width >= 768px) {
  .service-section .preview {
    order: 2;
  }
}

@media (width <= 768px) {
  .service-section {
    flex-wrap: wrap;
    max-width: 95vw;
    padding: 5vw;
  }

  .service-section .preview {
    width: 100%;
    max-width: 100%;
  }
}

.service-section .details .subtitle {
  color: #3da3db;
}

.service-section .details .title {
  font-size: xx-large;
  font-weight: 600;
}

.service-section .details {
  flex-direction: column;
  align-items: start;
  display: flex;
}

.service-section .preview {
  object-fit: cover;
  border-radius: 1rem;
  height: 18rem;
}

.service-section .subtitle-instance {
  font-size: .75rem !important;
  font-weight: 700 !important;
}

.service-section .class {
  flex: none !important;
}

.service-section .class-2 {
  opacity: .8 !important;
  flex: none !important;
}

.service-section .text-wrapper-2 {
  letter-spacing: 0;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3.125rem;
  position: relative;
}

.service-section .embrace-the-future {
  letter-spacing: 0;
  font-size: .875rem;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.service-section.property-1-0-variant-4 .text-wrapper-2 {
  color: #fff;
}

.service-section.property-1-0-default .text-wrapper-2, .service-section.property-1-0-variant-3 .text-wrapper-2 {
  color: #fffc;
}

.service-section.property-1-0-variant-2 .text-wrapper-2, .service-section.property-1-0-variant-4 .embrace-the-future {
  color: #fff;
}

.service-section.property-1-0-default .embrace-the-future, .service-section.property-1-0-variant-3 .embrace-the-future {
  color: #ffffff8a;
}

.service-section.property-1-0-variant-2 .embrace-the-future {
  color: #fff;
}

.services {
  text-align: center;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.services .header {
  background-color: #0000;
  background-image: url("header.fa333939.webp");
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50vh;
  padding: 1.875rem 0;
  display: flex;
  position: relative;
}

.services .navbar-instance {
  flex: none !important;
}

.services .design-component-instance-node {
  color: #ffffff8a !important;
}

.services .hero {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  margin-top: 3rem;
  padding: 0 1rem;
  display: flex;
  position: relative;
}

.services .text {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: .625rem;
  width: 100%;
  display: flex;
  position: relative;
}

.services .subtitle-2 {
  color: #fff;
  background-blend-mode: overlay !important;
  background-color: #383e8e !important;
  flex: none !important;
}

.services .h {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-size: 4rem;
  font-weight: 700;
  line-height: 4.8125rem;
  position: relative;
}

@media screen and (width <= 768px) {
  .services .h {
    font-size: 3rem;
  }
}

.services-cards {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  margin: 3rem 0;
  display: flex;
}

.services .our-forte-lies-in {
  color: #ffffffbf;
  letter-spacing: 0;
  text-align: center;
  max-width: 45rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.services .space {
  background-color: #fff;
  width: 100%;
  height: 3rem;
  position: relative;
}

.input {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 600px;
  display: flex;
  position: relative;
}

.input .add-your-full-name {
  color: #5757578a;
  letter-spacing: 0;
  width: fit-content;
  margin-top: -1px;
  font-family: Poppins, Helvetica;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.input input {
  background-color: #f4f4f4;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  padding: 16px;
  display: flex;
  position: relative;
}

.input .text-wrapper-2 {
  color: #03577a;
  letter-spacing: 0;
  width: fit-content;
  margin-top: -1px;
  font-family: Poppins, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.input .property-1-0-default {
  flex: none;
}

.input .property-1-0-variant-2 {
  height: 132px;
}

.contact {
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: inline-flex;
  position: relative;
}

.contact .header {
  background-color: #0000;
  background-image: url("header.fa333939.webp");
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  min-height: 50vh;
  padding: 1.875rem 1rem;
  display: flex;
  position: relative;
}

.contact .design-component-instance-node {
  flex: none !important;
}

.contact .navbar-instance {
  color: #ffffff8a !important;
}

.contact .hero {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  margin-top: 3rem;
  padding: 0 1rem;
  display: flex;
  position: relative;
}

.contact .text {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: .625rem;
  width: 100%;
  display: flex;
  position: relative;
}

.contact .subtitle-instance {
  background-blend-mode: overlay !important;
  background-color: #fff !important;
  flex: none !important;
}

.contact .hero .subtitle {
  color: #fff;
  background-blend-mode: overlay !important;
  background-color: #383e8e !important;
  flex: none !important;
}

.contact .h {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-family: Poppins, Helvetica;
  font-size: 4rem;
  font-weight: 700;
  line-height: 4.8125rem;
  position: relative;
}

@media screen and (width <= 768px) {
  .contact .h {
    font-size: 3rem;
  }
}

.contact .our-forte-lies-in {
  color: #ffffffbf;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-family: Poppins, Helvetica;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.contact .shaping {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 3rem;
  width: 100%;
  padding: 6.25rem 0;
  display: flex;
  position: relative;
}

.contact .head {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 1rem;
  width: 100%;
  display: flex;
  position: relative;
}

.contact .subtitle-3 {
  flex: none !important;
  justify-content: center !important;
}

.contact .let-us-know-more {
  color: #03577a;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-family: Poppins, Helvetica;
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.75rem;
  position: relative;
}

.contact .we-don-t-just-stop {
  color: #0000008a;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-family: Poppins, Helvetica;
  font-size: .875rem;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.contact .form {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 1.875rem;
  display: inline-flex;
  position: relative;
}

.contact .input-instance {
  flex: none !important;
  width: 29.5rem !important;
}
/*# sourceMappingURL=index.89f36269.css.map */

.shaping-card {
  align-items: center;
  border-radius: 4.5rem;
  display: inline-flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.shaping-card .continuous {
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.shaping-card .user-robot {
  height: 1.5rem !important;
  position: relative !important;
  width: 1.5rem !important;
}

.shaping-card.property-1-10-default {
  background-color: #3da3db40;
  gap: 1rem;
  padding: 0.625rem 1.5rem;
}

.shaping-card.property-1-10-variant-3 {
  background-color: #3da3db40;
  gap: 0.625rem;
  padding: 0.3125rem 0.875rem;
}

.shaping-card.property-1-10-variant-4 {
  background-color: #3da3db1a;
  gap: 0.625rem;
  padding: 0.3125rem 0.875rem;
}

.shaping-card.property-1-10-variant-2 {
  background-color: #3da3db1a;
  gap: 1rem;
  padding: 0.625rem 1.5rem;
}

.shaping-card.property-1-10-default .continuous {
  color: #03577a;
  font-size: 1.125rem;
}

.shaping-card.property-1-10-variant-3 .continuous {
  color: #03577a;
  font-size: 1rem;
}

.shaping-card.property-1-10-variant-4 .continuous {
  color: #3da3db;
  font-size: 1rem;
}

.shaping-card.property-1-10-variant-2 .continuous {
  color: #3da3db;
  font-size: 1.125rem;
}

.shaping-card.property-1-10-variant-2 .fi{
  color: #3da3db;
  font-size: 1.5rem;
  align-items: center;
  display: flex;
  justify-content: center;
}

.shaping-card.property-1-10-default .fi{
  color: #03577a;
  font-size: 1.5rem;
  align-items: center;
  display: flex;
  justify-content: center;
}
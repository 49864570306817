.support-card {
  align-items: flex-start;
  background-color: #00000033;
  border-radius: 0.9375rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 25.875rem;
  transition: transform 0.3s ease-in-out; /* Adding transition for smooth effect */
}

.support-card:hover {
  transform: translateY(-5px); /* Move the card up slightly on hover */
}

.support-card .property-1-variant2 {
  height: 2.25rem !important;
  position: relative !important;
  width: 2.25rem !important;
}

.support-card .frame-2 {
  align-items: flex-start;
  
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  text-align: start;
}

.support-card .text-wrapper-5 {
  
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.0625rem;
  position: relative;
}

.support-card .text-wrapper-6 {
  
  color: #ffffff8a;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.support-card.property-1-12-default {
  gap: 1.125rem;
  padding: 2.5rem 1.875rem;
}

.support-card.property-1-12-variant-2 {
  gap: 0.875rem;
  padding: 1.5rem;
}

.support-card.property-1-12-default .frame-2 {
  gap: 0.3125rem;
}

.support-card.property-1-12-variant-2 .frame-2 {
  gap: 0.25rem;
}

.support-card.property-1-12-default .text-wrapper-5 {
  font-size: 1.25rem;
}

.support-card.property-1-12-variant-2 .text-wrapper-5 {
  font-size: 1.125rem;
}

.support-card .fi{
  color: #3da3db;
  font-size: 1.5rem;
  align-items: center;
  display: flex;
  justify-content: center;
}
.services {
  align-items: center;
  background-color: #ffffff;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  text-align: center;
  justify-content: center;
  display: flex;
}

.services .header {
  align-items: center;
  background-color: transparent;
  background-image: url(../../img/header.webp);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 50vh;
  justify-content: space-between;
  padding: 1.875rem 0rem;
  position: relative;
  width: 100%;
}

.services .navbar-instance {
  flex: 0 0 auto !important;
}

.services .design-component-instance-node {
  color: #ffffff8a !important;
}

.services .hero {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 1.5rem;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-top: 3rem;
  padding: 0 1rem;
}

.services .text {
  align-items: center;

  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 0.625rem;
  position: relative;
  width: 100%;
}

.services .subtitle-2 {
  background-blend-mode: overlay !important;
  background-color: #383e8e !important;
  color: white;
  flex: 0 0 auto !important;
}

.services .h {
  color: #ffffff;
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 4.8125rem;
  position: relative;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .services .h {
    font-size: 3rem;

  }
}

.services-cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  margin: 3rem 0;
}

.services .our-forte-lies-in {
  color: #ffffffbf;

  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  max-width: 45rem;
}

.services .space {
  background-color: #ffffff;
  height: 3rem;
  position: relative;
  width: 100%;
}
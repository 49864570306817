.subtitle {
  align-items: center;
  border-radius: 4.5rem;
  display: inline-flex;
  gap: 0.625rem;
  position: relative;
}

.subtitle .custom-SAP-solutions {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.subtitle.variant-4 {
  padding: 0.25rem 0.875rem;
}

.subtitle.variant-2 {
  padding: 0.25rem 0.875rem;
}

.subtitle.variant-5 {
  background-color: #3da3db8a;
  padding: 0.125rem 0.875rem;
}

.subtitle.default {
  background-color: #3da3db;
  padding: 0.25rem 0.875rem;
}

.subtitle.variant-4 .custom-SAP-solutions {
  color: #03577a;
  font-size: 0.875rem;
  font-weight: 500;
}

.subtitle.variant-2 .custom-SAP-solutions {
  color: #000000;
  font-size: 0.875rem;
  font-weight: 500;
}

.subtitle.variant-3 .custom-SAP-solutions {
  color: #3da3db;
  font-size: 0.875rem;
  font-weight: 500;
}

.subtitle.variant-5 .custom-SAP-solutions {
  color: #03577a;
  font-size: 0.75rem;
  font-weight: 600;
}

.subtitle.default .custom-SAP-solutions {
  font-size: 0.875rem;
  font-weight: 500;
}

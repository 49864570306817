.input {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  width: 600px;
}

.input .add-your-full-name {
  color: #5757578a;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.input input {
  align-items: flex-start;
  align-self: stretch;
  background-color: #f4f4f4;
  border: 1px solid;
  border-color: #e5e5e5;
  border-radius: 10px;
  display: flex;
  padding: 16px;
  position: relative;
  width: 100%;
}

.input .text-wrapper-2 {
  color: #03577a;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.input .property-1-0-default {
  flex: 0 0 auto;
}

.input .property-1-0-variant-2 {
  height: 132px;
}

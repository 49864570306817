.home {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  line-height: 1.5;

}

.home .header {
  background-image:    linear-gradient(
    rgba(0, 0, 0, 0.45), 
    rgba(0, 0, 0, 0.45)
  ), url(../../img/header.webp);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* min-height: 100vh; */
  padding: 5rem 1rem 1rem 1rem;
  gap: 8rem;
}

.home .design-component-instance-node {
  flex: 0 0 auto !important;
}

.home .hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 8rem;
  gap: 1rem;
  max-width: 48rem;
}

.home .text-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;
}

.home .subtitle-instance {
  background-color: #ffffff !important;
}

.home .h {
  color: #ffffff !important;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.2;
}
@media screen and (max-width: 768px) {
  .home .h {
    font-size: 3rem;
  
  }
}
.home .h span{
  color: #3da3db;
}

.home .our-forte-lies-in {
  color: #ffffffbf;
  font-size: 1rem;
  font-weight: 500;
}

.home .logos-instance {
  display: flex !important;
  width: 100% !important;
}

.home .our-services {
  background-color: #ffffffe0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6.25rem;
  padding: 8rem 1rem;
  width: 100%;
}

.home .head {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 48rem;

}

.home .our-services-2 {
  font-size: 3rem;
  font-weight: 700;
}

.home h2{
  color: #03577a;
}

.home .we-don-t-just-stop {
  color: #0000008a;
  font-size: 0.875rem;
  font-weight: 500;
}

.home .cards {
  display: flex;
  gap: 1.4375rem;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  /* justify-content: center; */
}

.home .our-history {
  background-image: url(../../img/bg.webp);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 2.8125rem;
  box-shadow: 0.25rem 0.625rem 1.4375rem #00000029;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6.25rem;
  padding: 3.75rem 0.9375rem;
  width: 90vw;
  margin: 0 5vw;
}

.home .text-wrapper-9 {
  color: #3da3db;
  font-size: 3rem;
  font-weight: 700;
}

.home .we-don-t-just-stop-2 {
  color: #ffffff8a;
  font-size: 0.875rem;
  font-weight: 500;
}

.home .cards-2 {
  display: flex;
  flex-wrap: wrap;
  gap: 2.75rem 2.75rem;
  justify-content: center;
  width: 100%;
}

.home .shaping {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  padding: 6.25rem 0rem;
  width: 100%;
}

.home .subtitle-2 {
  flex: 0 0 auto !important;
  justify-content: center !important;
}

.home .text-wrapper-10 {
  color: #03577a;
  font-size: 3rem;
  font-weight: 700;
}

.home .cards-3 {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem 1.5rem;
  justify-content: center;
  padding: 0rem 9.6875rem;
  width: 100%;
}

.home .shaping-card-instance {
  flex: 0 0 auto !important;
  overflow: unset !important;
}

.home .success {
  background-image: url(../../img/bg.webp);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 6.25rem 0.9375rem;
  width: 100%;
  align-items: center;
}

.home .subtitle-3 {
  background-color: #03577a !important;
}

.home .subtitle-4 {
  color: #ffffff !important;
  font-size: 0.75rem !important;
}

.home .text-wrapper-11 {
  color: #3da3db;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
}

.home .cards-4 {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem 1.25rem;
  justify-content: center;
  width: 100%;
}


.footer {
  background-color: #0b1123;
  display: flex;
  flex-wrap: wrap;
  gap: 5rem;
  justify-content: space-between;
  padding: 4rem 6.25rem 1.25rem 6.25rem;
  width: 100%;
  flex-direction: column;
}

.eco-logo {
  height: 4.5rem !important;
  width: 10.1131rem !important;
}

.links {
  display: flex;
  gap: 3rem;
  text-align: start;
  flex-wrap: wrap;
}

.frame-3 {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.text-wrapper-12 {
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 600;
}

.text-wrapper-13 {
  color: #ffffff8a;
  font-size: 0.875rem;
  font-weight: 500;
}

footer.flex{
  display: flex;
  flex-wrap: wrap;
  gap: 1.875rem;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 768px) {
.logo-footer{
  justify-content: center;
}

}